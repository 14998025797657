import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import MauticForm from '../../components/ui/Forms/MauticForm/MauticForm';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './gsml.scss';

interface GSMLProps {
    data: {
        file: FluidImage;
    };
}

const GSML: React.FC<GSMLProps> = (props: GSMLProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Get started with Machine Learning free course"
                url="get_started_with_machine_learning/"
                description="Email course on how to start learning Machine Learning"
                image={props.data.file.childImageSharp.fluid.src}
            />
            <PageSection className="gsml">
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>
                                Get started with Machine Learning free email
                                course
                            </h1>
                            <h2>
                                Start with Machine Learning with a 5-part email
                                course where you will understand what you need
                                to learn and what are the best resources to do
                                that
                            </h2>
                            <MauticForm
                                formId="3"
                                formName="gsmlcourse"
                                isDark={false}
                            />
                        </Block>
                        <Block
                            direction={Direction.vertical}
                            className="gsml__image"
                        >
                            <Img
                                fluid={props.data.file.childImageSharp.fluid}
                                alt="Get started with Machine Learning free email
                                course"
                            ></Img>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};
export const gsmlQuery = graphql`
    {
        file(relativePath: { eq: "gsml.png" }) {
            childImageSharp {
                fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }
    }
`;

export default GSML;
